import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import * as dfd from 'danfojs';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { requestPending } from '../../common/uiSlice';
import { serializeDataframe } from './downloadComponentFunctions';

export type AreaAggregationType = Record<number, 'Kunta' | 'Suuralue' | 'Tilasto- tai pienalue'>
export const AREA_AGGREGATION: AreaAggregationType = {
    1: 'Kunta',
    2: 'Suuralue',
    3: 'Tilasto- tai pienalue',
}

type DownloadComponentPropsType = {
    data: dfd.DataFrame;
    areaNames: Record<string, string>;
    selectedAreas: string[];
    selectedAges: { from: number, to: number };
    ageGroupingMap: Record<string, { from: number; to: number }[]>; // e.g. 5-year age groups -> ['0-4', '5-9', ..]
};
export const DownloadComponent = ({ data, areaNames, selectedAreas, selectedAges, ageGroupingMap }: DownloadComponentPropsType) => {

    const dispatch = useAppDispatch()

    const groupingNames = Object.keys(ageGroupingMap);
    const [selectedGrouping, setSelectedGrouping] = useState(groupingNames[0] || '');
    const [ selectedAreaDivision, setSelectedAreaDivision ] = useState(3)
    const [showOrientationOptions, setShowOrientationOptions] = useState(false);

    const [ download, setDownload ] = useState<string | null>(null)
    const [ worker, setWorker ] = useState<Worker | null>(null);

    useEffect(() => {

        const worker = new Worker(new URL("worker.ts", import.meta.url))

        worker.onmessage = (event) => {
            console.log('worker finished')
            dispatch(requestPending(false))
            setDownload(event.data)
        }

        setWorker(worker)

        return () => {
            worker.terminate();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (!download) return

        // Add UTF-8 BOM to the start of the CSV data. Let's see if this helps with Excel's encoding issues.
        const bom = '\uFEFF';
        const blob = new Blob([bom + download], { type: 'text/csv;charset=utf-8;' });
    
        // Format the file name with the current timestamp and selected orientation
        const now = new Date();
        const formattedTimestamp = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}`;
        const fileName = `export-${formattedTimestamp}.csv`;
    
        // Create a temporary link element and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownload(null)

    }, [download])

    const handleDownload = async (fileformat: 'excel' | 'csv', orientation: 'wide' | 'tall') => {

        if (fileformat === 'excel') {
            throw new Error('Excel format is currently not supported');
        }

        dispatch(requestPending(true))

        worker?.postMessage({ d: serializeDataframe(data), areaNames, selectedAreas, selectedAges, ageGroupingMap, selectedGrouping, orientation, areaDivision: selectedAreaDivision })
    };
    
    
    const getAgeGroupingCaption = (ageGroups: {from: number, to: number}[]) => {
        const MAX_ITEMS = 10;
        const ageGroupStrings = ageGroups.map(({ from, to }) => from === to ? `${from}` : `${from}-${to}`);
        return ageGroupStrings.length <= MAX_ITEMS
            ? ageGroupStrings.join(', ')
            : `${ageGroupStrings.slice(0, MAX_ITEMS).join(', ')}, ...`;
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            sx={{ minWidth: 290, gap: 2, backgroundColor: '#C4E8FA', padding: 2, borderRadius: 1 }}
        >
            {/* Collapsible Header */}
            <Box
                display="flex"
                alignItems="center"
                onClick={() => setShowOrientationOptions(!showOrientationOptions)}
                sx={{ cursor: 'pointer', width: '100%', justifyContent: 'space-between' }}
            >
                <Box display="flex" alignItems="center">
                    <span className="material-icons-outlined" style={{ marginRight: 8 }}>download</span>
                    <Typography variant="h6" component="h6">
                        Lataa valittu aineisto
                    </Typography>
                </Box>
                <span
                    className="material-icons-outlined"
                    style={{
                        transition: 'transform 0.3s ease',
                        transform: showOrientationOptions ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                >
                    expand_more
                </span>
            </Box>
    
            {/* Collapsible Content */}
            <Collapse in={showOrientationOptions} sx={{ width: '100%' }}>

            <Select
                    fullWidth
                    value={selectedAreaDivision.valueOf()}
                    onChange={(e) => setSelectedAreaDivision(Number(e.target.value))}
                    displayEmpty
                    sx={{ width: '100%', mt: 2 }}
                >
                    {Object.entries(AREA_AGGREGATION).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                            <Box>
                                <Typography variant="body1">{value}</Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>

                {/* Age Grouping Type Dropdown */}
                <Select
                    fullWidth
                    value={selectedGrouping}
                    onChange={(e) => setSelectedGrouping(e.target.value)}
                    displayEmpty
                    sx={{ width: '100%', mt: 2 }}
                >
                    {groupingNames.map((groupingName) => (
                        <MenuItem key={groupingName} value={groupingName}>
                            <Box>
                                <Typography variant="body1">{groupingName}</Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {getAgeGroupingCaption(ageGroupingMap[groupingName])}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
    
                {/* Download Options */}
                <Box display="flex" flexDirection="column" gap={1} mt={2}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleDownload('csv', 'wide')}
                        startIcon={<span className="material-icons-outlined">view_column</span>}
                        sx={{ justifyContent: 'flex-start', pl: 2 }}
                    >
                        CSV, Ikäryhmät sarakkeina
                    </Button>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleDownload('csv', 'tall')}
                        startIcon={<span className="material-icons-outlined">view_headline</span>}
                        sx={{ justifyContent: 'flex-start', pl: 2 }}
                    >
                        CSV, Ikäryhmät riveinä
                    </Button>
                </Box>
            </Collapse>
        </Box>
    );
    
};