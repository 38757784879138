import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"

type IndexedCohortType = {
    'f': {
        [age: number]: number
    },
    'm': {
        [age: number]: number
    }
}

export type IndexedCohortByAreaByYearType = {
    [year: number]: {
        [area: string]: IndexedCohortType
    }
}

const CustomTooltipItem = ({ name, value, color }: { name:string, value:any, color:string }) => {
    return (
        <li className='recharts-tooltip-item' style={{ color, display: 'block', paddingTop: '4px', paddingBottom: '4px' }}>
            <span className='recharts-tooltip-item-name'>{name}</span>
            <span className='recharts-tooltip-item-separator'> : </span>
            <span className='recharts-tooltip-item-value'>{value}</span>
        </li>
    )
}

type YearlySums = {
    sums: {
        year: number,
        oldProduction?: number,
        newProduction?: number,
        cumulative?: number,
        history?: number,
    }[],
    hasNewProduction?: boolean,
}
const CustomTooltip = ({ active, payload, label }: any) => {

    const { t } = useTranslation()

    if (active && payload && payload.length) {

        return (
            <div className="recharts-default-tooltip" style={{ border: '1px solid rgb(204, 204, 204)', padding: '10px', whiteSpace: 'nowrap', backgroundColor: '#FFFFFF' }}>
                <p className="recharts-tooltip-label" style={{ margin: 0 }}>{label}</p>
                <ul className="recharts-tooltip-item-list" style={{ padding: 0, margin: 0}}>
                    {
                        payload.map((entry: any, index: any) => {

                            const { dataKey, name, value, color, payload } = entry

                            if (dataKey !== 'cumulative') {
                                return (
                                    <CustomTooltipItem { ...{ name, value, color } } key={`item-${dataKey}-${index}`} />
                                )
                            } else {

                                const { newProduction } = payload

                                return (
                                    <>
                                        <CustomTooltipItem { ...{ name: t('queryView.newProduction'), value: newProduction, color } } key={`item-${dataKey}`} />
                                        <CustomTooltipItem { ...{ name: t('queryView.sum'), value, color: '#000000' } } key={`item-${dataKey}-${index}`} />
                                    </>
                                )
                            }
                        })
                    }
                </ul>
            </div>
        );
    }

    return null;
}

export const [OLD_PRODUCTION_IDX, NEW_PRODUCTION_IDX, HISTORY_IDX] = [0, 1, 2]

export const AdjustedPopulationChangeChart: React.FC<{
    data?: IndexedCohortByAreaByYearType[],
    areas: string[],
    ages: { from: number, to: number }
}> = ({ data, areas, ages }) => {

    const { t } = useTranslation()

    const [populationChange, setPopulationChange] = useState<YearlySums | undefined>(undefined)

    const filterCohortByAge = (cohort: IndexedCohortType, ages: { from: number, to: number }): IndexedCohortType => {
        return {
            f: Object.entries(cohort.f).reduce((prev, [key, value]) => {
                const age = Number(key)
                return age >= ages.from && age <= ages.to ? { ...prev, [key]: value } : prev
            }, {}),
            m: Object.entries(cohort.m).reduce((prev, [key, value]) => {
                const age = Number(key)
                return age >= ages.from && age <= ages.to ? { ...prev, [key]: value } : prev
            }, {})
        }
    }

    const sumCohortsPerYear = (data: IndexedCohortByAreaByYearType[]): YearlySums => {

        const years = Object.keys(data[OLD_PRODUCTION_IDX]).map(y => Number(y))
        let newProductionSum = 0

        const adjusted = years.map(year => {

            const oldProduction = areas.reduce((prev, area) => {
                const cohort = filterCohortByAge(data[OLD_PRODUCTION_IDX][year][area], ages)
                return prev + Object.values(cohort.f).reduce((prev, curr) => prev + curr, 0) + Object.values(cohort.m).reduce((prev, curr) => prev + curr, 0)
            }, 0)

            const newProduction = areas.reduce((prev, area) => {
                const cohort = filterCohortByAge(data[NEW_PRODUCTION_IDX][year][area], ages)
                return prev + Object.values(cohort.f).reduce((prev, curr) => prev + curr, 0) + Object.values(cohort.m).reduce((prev, curr) => prev + curr, 0)
            }, 0)

            newProductionSum += newProduction

            return {
                year: Number(year),
                oldProduction: Math.round(oldProduction),
                newProduction: Math.round(newProduction),
                cumulative: Math.round(oldProduction + newProduction),
            }
        })

        const historyYears = Object.keys(data[HISTORY_IDX]).map(y => Number(y))

        const history = historyYears.map(year => {

            const history = areas.reduce((prev, area) => {
                if (!data[HISTORY_IDX][year][area]) return prev
                const cohort = filterCohortByAge(data[HISTORY_IDX][year][area], ages)
                return prev + Object.values(cohort.f).reduce((prev, curr) => prev + curr, 0) + Object.values(cohort.m).reduce((prev, curr) => prev + curr, 0)
            }, 0)

            return { year, history: Math.round(history) }
        })

        return { sums: history.concat(adjusted as any), hasNewProduction: newProductionSum > 0 }
    }

    useEffect(() => {

        if (!data || !areas.length) return

        const diagramData = sumCohortsPerYear(data)
        setPopulationChange(diagramData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, areas])

    const formatter = (value: any, name: any, props: any) => {
        console.log(props)
        const { dataKey, payload } = props
        if (dataKey === 'cumulative') {
            // return `${t('queryView.newProduction')}: ${payload.newProduction}<br>${t('queryView.sum')}: ${payload.cumulative}`
            return [`${t('queryView.newProduction')}: ${payload.newProduction}<br>${t('queryView.sum')}: ${payload.cumulative}`]
        }
        return value
    }

    return populationChange ? (
        <ResponsiveContainer width='100%' height='100%' aspect={0.9}>
            <LineChart data={populationChange.sums}>
                <XAxis dataKey='year' />
                <YAxis />
                <Tooltip formatter={formatter} content={<CustomTooltip />} />
                <Legend />
                <Line type='monotone' dataKey={'history'} name={t('queryView.history')} fill='#000000' stroke='#000000' strokeWidth={4} dot={false} />
                <Line type='monotone' dataKey={'oldProduction'} name={t('queryView.oldProduction')} fill='#70C4F2' stroke='#70C4F2' strokeWidth={4} dot={false} />
                {populationChange.hasNewProduction && <Line type='monotone' dataKey={'cumulative'} name={t('queryView.sum')} fill='#A3EBA3' stroke='#A3EBA3' strokeWidth={4} dot={false} />}
            </LineChart>
        </ResponsiveContainer>
    ) : null
}
