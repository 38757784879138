import { Box, FormControlLabel, Stack, Switch, Tab, Tabs } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { selectDialogOpen } from "../../common/uiSlice";
import AlertDialog, { AlertDialogProps } from "../../components/AlertDialog";
import { selectCurrentMunicipalityCode } from "../apis/appSlice";
import { useGetAvailableSpatialDataQuery } from "../apis/spatialSlice";
import AreaTable from "./AreaTable";
import { MapView } from "./MapView";

export const AreaView = () => {

    const { t } = useTranslation()
    const dialogOpen = useAppSelector(selectDialogOpen)
    const [dialogProps, setDialogProps] = useState<AlertDialogProps>({ title: '', message: '' })

    const municipalityCode = useAppSelector(selectCurrentMunicipalityCode)

    const { data: spatialData } = useGetAvailableSpatialDataQuery()
    const spatial = useMemo(() => spatialData?.map(s => ({ id: s.id, label: s.name, geojson: s.geojson })), [spatialData])

    const [editMode, setEditMode] = useState<boolean>(false)

    const [tab, setTab] = useState<number>(0)
    const changeTab = (_: React.SyntheticEvent, tabIndex: number) => {
        setTab(tabIndex)
    }

    useEffect(() => {
        setTab(0)
    }, [ municipalityCode])

    return (
        <>
            <Stack>
                <Box display={'flex'}>
                    <Tabs value={tab} onChange={changeTab}>
                        <Tab icon={<span className="material-icons-outlined">table_chart</span>} />
                        { spatial?.length ? <Tab icon={<span className="material-icons-outlined">map</span>} /> : null }
                    </Tabs>
                    {
                        <FormControlLabel
                            control={
                                <Switch checked={editMode} value={'check'} onChange={() => setEditMode(!editMode)} />
                            }
                            label={t('general.editMode')}
                        />
                    }
                </Box>
                {
                    tab === 0 ? (
                        <AreaTable editMode={editMode} setEditMode={setEditMode} setDialogProps={setDialogProps} />
                    ) : (
                        spatial?.length ? <MapView municipalityCode={municipalityCode} spatial={spatial!} /> : null
                    )
                }
            </Stack>
            { dialogOpen ? <AlertDialog { ...dialogProps }  /> : null }
        </>
    )
}