import { Backdrop, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../app/hooks";

const BusyIndicator = () => {

    const [show, setShow] = useState(false)
    const isFetching = useAppSelector(state => state.uiSlice.requestPending)

    const isPending = useCallback(() => isFetching, [isFetching])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(isPending())
        }, 1500)

        // useEffect caches the state so we must clear the previous timer if state changes while former is running
        return () => {
            clearTimeout(timeout)
        }
    }, [isFetching, isPending])

    return (
        <Backdrop
            sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={show}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

    )
}

export default BusyIndicator;