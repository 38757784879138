import { t } from 'i18next';
import { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useAppSelector } from '../../app/hooks';
import { getPopulationAgeRanges, getPopulationPercentageRanges } from '../../common/functions';
import { AgeGroupChart } from '../../components/charts/LineCharts';
import { CohortByAreaByYearType } from '../../types';
import { selectCurrentForecast, useGetForecastMunicipalDataQuery } from '../apis/forecastApi';

export const AGE_GROUPS = [
  { from: 0, to: 4 },
  { from: 5, to: 9 },
  { from: 10, to: 14 },
  { from: 15, to: 19 },
  { from: 20, to: 24 },
  { from: 25, to: 29 },
  { from: 30, to: 34 },
  { from: 35, to: 39 },
  { from: 40, to: 44 },
  { from: 45, to: 49 },
  { from: 50, to: 54 },
  { from: 55, to: 59 },
  { from: 60, to: 64 },
  { from: 65, to: 69 },
  { from: 70, to: 74 },
  { from: 75, to: 79 },
  { from: 80, to: 84 },
  { from: 85, to: 89 },
  { from: 90, to: 94 },
  { from: 95, to: 100 },
]

export type PopulationGroupPercentages = {
  [group: string]: {
    label: string,
    change: [number, number],
  }
}[]

export type PopulationGroupSizes = {
  [year: number]: {
    [sex: string]: [{
      [ageGroup: string]: {
        label: string,
        size: number,
      }
    }]
  }
}[]

const ChangeBarChart = () => {

  const forecast = useAppSelector(selectCurrentForecast)

  const { data } = useGetForecastMunicipalDataQuery({ forecastId: forecast!.id! })

  const ageGroups = useMemo(() => {
    if (!!!data) return []
    return getPopulationPercentageRanges(data.data as CohortByAreaByYearType, AGE_GROUPS)
  }, [data])

  const tooltipFormatter = (value: (string | number)[], name: string | number) => {
    return [`${value[0]} - ${value[1]} %`, t('charts.change')]
  }

  const legendFormatter = (value: any) => {
    return t('charts.changeInTimePeriodByAge')
  }

  return (
    <ResponsiveContainer width="100%" height="100%" aspect={0.65}>
      <BarChart
        layout="vertical"
        stackOffset="sign"
        data={Object.values(ageGroups)}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis orientation='top' type='number' domain={[-1, 1]} tickCount={9} />
        <YAxis dataKey='label' type='category' />
        <Tooltip formatter={tooltipFormatter} />
        <Legend formatter={legendFormatter} />
        <Bar dataKey="change" fill='#C4E8FA' stroke='#70C4F2' barSize={15} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export const AgeGroupDiagram = () => {

  const forecast = useAppSelector(selectCurrentForecast)

  const { data } = useGetForecastMunicipalDataQuery({ forecastId: forecast!.id! })

  const { ageGroups, maxSize, minYear, maxYear } = useMemo(() => {
    if (!!!data) return { ageGroups: [], maxSize: 0, minYear: 0, maxYear: 0 }
    return getPopulationAgeRanges(data.data as CohortByAreaByYearType, AGE_GROUPS)
  }, [data])

  return ageGroups.length > 0 ? <AgeGroupChart ageGroups={ageGroups} maxSize={maxSize} minYear={minYear} maxYear={maxYear} />  : null
}

export default ChangeBarChart;
