import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const appVersion = process.env.REACT_APP_VERSION;
const versionCheckInterval = 1000 * 60 * 5 // 5 minutes

export const UpdateApp = () => {

    const { t } = useTranslation()

    /** The remote version fetched from JSON */
    const [newVersion, setNewVersion] = useState(appVersion);
    const [showNewVersion, setShowNewVersion] = useState(false)
    const [checkInterval, setCheckInterval] = useState(versionCheckInterval)

    const hardReloadPage = () => {
        window.location.reload();
    }

    const fetchRemoteVersion = useCallback(() =>
        fetch(`/appversion.json?${Date.now()}`, { cache: 'no-store' })
            .then((res) => {
                return res.json();
            })
            .then((data: { appVersion: string }) => {
                setNewVersion(data.appVersion)
                return data.appVersion;
            })
        , [])

    useEffect(() => {
        fetchRemoteVersion().then((version) => {
            if (version !== appVersion) {
                console.log('reload page', version, newVersion)
                hardReloadPage()
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        /** interval run and show prompt if update is needed */
        const timer = setInterval(() => {
            fetchRemoteVersion().then((version) => {
                if (version !== appVersion) {
                    setShowNewVersion(true);
                } else {
                    console.log("version not changed")
                }
            });
        }, checkInterval)

        return () => clearInterval(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ checkInterval ])

    return showNewVersion ? (
        <Dialog open={true}>
            <DialogTitle>{t('general.updateAvailable')}</DialogTitle>
            <DialogContent>
                <Typography>{t('general.updateAvailableDescription')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => { setCheckInterval(1000 * 60 * 10); setShowNewVersion(!showNewVersion) }}>
                    {t('general.remindLater')}
                </Button>
                <Button variant="contained" onClick={hardReloadPage}>{t('general.update')}</Button>
            </DialogActions>
        </Dialog>
    ) : null
}