import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import BusyIndicator from '../components/BusyIndicator';
import Layout from '../components/Layout';
import AdjustmentView from './adjustment/AdjustmentView';
import MainAdminPage from './admin-page/MainAdminPage';
import { selectCurrentMunicipalityCode } from './apis/appSlice';
import { forecastApi } from './apis/forecastApi';
import { useGetHistoryYearsQuery, useGetSupportedParameterMethodsQuery, useGetUserDataQuery } from './apis/projectionParametersSlice';
import { AreaView } from './area/AreaView';
import BuildingProductionView from './building-production/BuildingProductionView';
import MainPage from './main-page/MainPage';
import { MunicipalityView } from './municipality/MunicipalityView';
import QueryView from './query/QueryView';
import { UpdateApp } from './UpdateApp';

const AuthenticatedApp = () => {

    const dispatch = useAppDispatch()

    useGetUserDataQuery()
    useGetSupportedParameterMethodsQuery()
    useGetHistoryYearsQuery()

    const currentMunicipality = useAppSelector(selectCurrentMunicipalityCode)

    useEffect(() => {
        if (!currentMunicipality) return
        dispatch(forecastApi.util.invalidateTags(['Forecasts', 'MunicipalData', 'AreaData', 'MunicipalSummaryData', 'AreaSummaryData']))
        dispatch(forecastApi.endpoints.getForecasts.initiate())
    }, [currentMunicipality, dispatch])


    return (
        <>
            { process.env.REACT_APP_ENV === 'local' ? null : <UpdateApp /> }
            <BusyIndicator />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<MainPage />} />
                        <Route path="admin" element={<MainAdminPage />} />
                        <Route path="municipality" element={<MunicipalityView />} />
                        <Route path="area" element={<AreaView />} />
                        <Route path="building" element={<BuildingProductionView />} />
                        <Route path="adjustment" element={<AdjustmentView />} />
                        <Route path="query" element={<QueryView />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default AuthenticatedApp