import { TreeViewBaseItem } from "@mui/x-tree-view";
import { AreaType } from "../../types";

const UNKNOWN_AREA_ID = '999999'

export const getAreaHierarchy = (major: AreaType, small: AreaType):TreeViewBaseItem[] => {

    const areaTree = Object.entries(major).map(([code, { name: label }]) => {

        return {
            id: code,
            label,
            children: Object.entries(small)
                .filter(([smallCode]) => !smallCode.endsWith(UNKNOWN_AREA_ID))
                .filter(([smallCode]) => smallCode.startsWith(code))
                .map(([smallCode, { name: label }]) => ({
                    id: smallCode,
                    label
                }))
        }
    })

    return areaTree
}