import Box from '@mui/material/Box'
import { RichTreeView, TreeViewBaseItem, useTreeViewApiRef } from '@mui/x-tree-view'
import { useRef } from 'react'

const getItemDescendantsIds = (item: TreeViewBaseItem) => {
  const ids: string[] = []
  item.children?.forEach((child) => {
    ids.push(child.id)
    ids.push(...getItemDescendantsIds(child))
  })
  return ids
}

type AreaSelectionPropsType = {
  areas: TreeViewBaseItem[],
  selectedAreas: string[],
  setSelectedAreas: (areas: string[]) => void,
  props?: any,
}

export const AreaSelection = ({ areas, selectedAreas, setSelectedAreas, props = {} }: AreaSelectionPropsType) => {

  const toggledItemRef = useRef<{ [itemId: string]: boolean }>({})
  const apiRef = useTreeViewApiRef()
  const lastClickTime = useRef(0)
  const prevToggledItemRef = useRef<{ [itemId: string]: boolean }>({})

  const handleDoubleClick = (clickedItemId: string) => {
    console.log("Handling a double click on item with id:", clickedItemId)
    const clickedItem = (apiRef.current! as any).getItem(clickedItemId)
    const descendants = getItemDescendantsIds(clickedItem)
    setSelectedAreas([clickedItemId, ...descendants])

    prevToggledItemRef.current = toggledItemRef.current
    toggledItemRef.current = {}
  }

  const handleClick = (newSelectedItems: string[]) => {
    setSelectedAreas(newSelectedItems)

    const itemsToSelect: string[] = []
    const itemsToUnSelect: { [itemId: string]: boolean } = {}

    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = (apiRef.current! as any).getItem(itemId)
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item))
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true
        })
      }
    })

    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...newSelectedItems, ...itemsToSelect].filter(
          (itemId) => !itemsToUnSelect[itemId],
        ),
      ),
    )

    setSelectedAreas(newSelectedItemsWithChildren)

    prevToggledItemRef.current = toggledItemRef.current
    toggledItemRef.current = {}
  }

  const handleSelectedItemsChange = (event: React.SyntheticEvent, newSelectedItems: string[]) => {
    // Handle as normal or double click?
    const currentTime = new Date().getTime()
    const DOUBLE_CLICK_DELAY = 800 // milliseconds
    const withinDelay = currentTime - lastClickTime.current < DOUBLE_CLICK_DELAY
    const sameItem = Object.keys(toggledItemRef.current).every((key) => prevToggledItemRef.current[key] !== undefined)
    if (withinDelay && sameItem) {
      const clickedItemId = Object.keys(toggledItemRef.current)[0]
      handleDoubleClick(clickedItemId) 
    } else {
      handleClick(newSelectedItems) 
    }
    lastClickTime.current = currentTime
  }

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {
    toggledItemRef.current[itemId] = isSelected
  }

  return (
    <Box sx={{ minHeight: 352, minWidth: 290 }}>
      <RichTreeView
        { ...props }
        multiSelect
        checkboxSelection
        apiRef={apiRef}
        items={areas}
        selectedItems={selectedAreas}
        onSelectedItemsChange={handleSelectedItemsChange}
        onItemSelectionToggle={handleItemSelectionToggle}
      />
    </Box>
  )
}
