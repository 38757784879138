import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Bar, ComposedChart, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart"

export type PopulationChangeType = {
    year: number,
    changePercentage: number,
    total: number,
}[]

type PopulationChangeChartType = {
    data: PopulationChangeType,
    aspect?: number,
}

export const PopulationChangeChart = ({ data, aspect = 2 }: PopulationChangeChartType) => {

    const { t } = useTranslation()

    const tooltipFormatter = (value: number, name: string) => [name === 'total' ? Math.round(value) : value.toFixed(2), t(`charts.${name}`)]

    const legendFormatter = (value: any) => {
        return t(`charts.${value}`)
    }

    return (
        <Box>

            <Typography variant='h3' style={{ fontSize: '1.2rem', fontWeight: 700, paddingTop: '0.6rem', paddingBottom: '0.6rem' }}>
                {t('charts.yearlyChangeInForecastPeriod')}
            </Typography>

            <ResponsiveContainer aspect={aspect} width={'100%'} height={'100%'}>
                <ComposedChart data={data}>
                    <XAxis dataKey="year" />
                    <YAxis yAxisId={'change'} orientation="right" />
                    <YAxis yAxisId={'total'} />

                    <Tooltip formatter={tooltipFormatter} />
                    <Legend formatter={legendFormatter} />

                    <Bar dataKey={'changePercentage'} stackId="1" fill='#C4E8FA' stroke='#C4E8FA' yAxisId={'change'} />
                    <Line type="monotone" dataKey="total" stroke="#000000" strokeWidth={4} dot={false} yAxisId={'total'} />
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    )
}

type StackedPopulationChartType = {
    data: {
        year: number,
        migration: number,
        interMunicipalMigration: number,
        naturalChange: number,
        total: number,
    }[]
}

export const StackedPopulationChart = ({ data }: StackedPopulationChartType) => {

    const { t } = useTranslation()

    const tooltipFormatter = (value: number, name: string | number) => [name === 'total' ? Math.round(value) : value.toFixed(2), t(`charts.${name}`)]
    const legendFormatter = (value: any) => t(`charts.${value}`)

    return (
        <Box>

            <Typography variant='h3' style={{ fontSize: '1.2rem', fontWeight: 700, paddingTop: '0.6rem', paddingBottom: '0.6rem' }}>
                {t('charts.yearlyChangePerType')}
            </Typography>

            <ResponsiveContainer aspect={2} width={'100%'} height={'100%'}>
                <ComposedChart data={data} stackOffset="sign">
                    <XAxis dataKey="year" />
                    <YAxis />

                    <Tooltip formatter={tooltipFormatter} />
                    <Legend formatter={legendFormatter} />

                    <ReferenceLine y={0} stroke="#000" />
                    <Bar dataKey={'migration'} stackId="1" fill='#70C4F2' stroke='#70C4F2' />
                    <Bar dataKey={'interMunicipalMigration'} stackId="1" fill='#C4E8FA' stroke='#C4E8FA' />
                    <Bar dataKey={'naturalChange'} stackId="1" fill='#A3EBA3' stroke='#A3EBA3' />
                    <Line type="monotone" dataKey="total" stroke="#000000" strokeWidth={4} dot={false} />
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    )
}

type AdjustmentsChartPropsType = CategoricalChartProps & {
    adjustmentData: {
        year: number,
        existingBuildings: number,
        newBuildings: number,
        areaForecast: number,
    }[]
}
export const AdjustmentsChart = ({ adjustmentData, ...props }: AdjustmentsChartPropsType) => {

    const { t } = useTranslation()

    const tooltipFormatter = (value: number, name: string | number) => [Math.round(value), t(`charts.${name}`)]
    const legendFormatter = (value: any) => t(`charts.${value}`)

    return (
        <ComposedChart data={adjustmentData} stackOffset='sign' { ...props }>
            <XAxis dataKey='year' />
            <YAxis />

            <Tooltip formatter={tooltipFormatter} />
            <Legend formatter={legendFormatter} />

            <ReferenceLine y={0} stroke='#000' />
            <Bar dataKey={'existingBuildings'} stackId='1' fill='#70C4F2' stroke='#70C4F2' />
            <Bar dataKey={'newBuildings'} stackId='1' fill='#C4E8FA' stroke='#C4E8FA' />

            <Line type='monotone' dataKey='areaForecast' stroke='#000000' strokeWidth={4} dot={false} />

        </ComposedChart>
    )
}