import { Slider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { AreaType, CohortByAreaByYearType } from '../../types';
import { useGetAdjustedDataForecastForForecastQuery, useGetDetailedHistoryQuery } from '../apis/apiSlice';
import { useGetAreasQuery } from '../apis/areaSlice';
import { selectCurrentForecast } from '../apis/forecastApi';
import { AreaSelection } from './AreaSelection';
import { getAreaHierarchy } from './queryViewFunctions';

import * as dfd from 'danfojs';
import { selectCurrentMunicipality } from '../apis/appSlice';
import { AdjustedPopulationChangeChart, IndexedCohortByAreaByYearType, NEW_PRODUCTION_IDX, OLD_PRODUCTION_IDX } from './AdjustedPopulationChangeChart';
import { calculateAgeGroups, DataframeAgeGroupChart, findMaxAgeGroupSize } from './DataframeAgeGroupChart';
import { DownloadComponent } from './DownloadComponent';

const UNKNOWN_AREA_ID = '999999'
const SMALL_AREA_IDX = 0
const MAJOR_AREA_IDX = 1

const generateOneYearAgeGroups = (start: number, end: number) =>
    Array.from({ length: end - start + 1 }, (_, i) => ({
        from: start + i,
        to: start + i,
    }));

const generateFiveYearAgeGroups = (start: number, end: number) =>
    Array.from({ length: Math.floor((end - start) / 5) + 1 }, (_, i) => {
        const rangeStart = start + i * 5;
        const rangeEnd = rangeStart + 4;
        return {
            from: rangeStart,
            to: rangeEnd,
        };
    });

const jsonToDataFrame = (oldProduction: CohortByAreaByYearType, newProduction: CohortByAreaByYearType): dfd.DataFrame => {

    const columns = ['year', 'area', Object.values(Object.values(oldProduction)[0]).map(cohort => Object.keys(cohort))[0]].flat()
    const flat = Object.entries(oldProduction).map(([year, areaData]) => [
        ...Object.entries(areaData).map(([area, cohort]) => [
            year,
            area,
            ...Object.entries(cohort).map(([key, value]) => value + newProduction[year][area][key])
        ])
    ]).flat()

    return new dfd.DataFrame(flat, { columns, index: flat.map(([year, area]) => `${year}_${area}`) })
}

const getLocColumns = (ages: { from: number, to: number }) => [
    'year',
    'area',
    ...Array.from(
        { length: ages.to - ages.from + 1 },
        (_, i) => `f${ages.from + i}`
    ),
    ...Array.from(
        { length: ages.to - ages.from + 1 },
        (_, i) => `m${ages.from + i}`
    )
]

const indexCohorts = (cohorts: CohortByAreaByYearType): IndexedCohortByAreaByYearType => {

    return Object.entries(cohorts).reduce((prev, [year, areaData]) => {
        return {
            ...prev,
            [year]: Object.entries(areaData).reduce((prev, [area, cohortData]) => {
                return {
                    ...prev,
                    [area]: {
                        f: Object.entries(cohortData).reduce((prev, [key, value]) => key.startsWith('f') ? { ...prev, [Number(key.slice(1))]: value } : prev, {}),
                        m: Object.entries(cohortData).reduce((prev, [key, value]) => key.startsWith('m') ? { ...prev, [Number(key.slice(1))]: value } : prev, {})
                    }
                }
            }, {})
        }
    }, {} as IndexedCohortByAreaByYearType)
}

const selectRowsAndColumns = (dataframe: dfd.DataFrame, rows: string[], columns: string[]): dfd.DataFrame => {
    const selectMask = dataframe.index.map(index => rows.includes(index.toString()));
    const selected = dataframe.loc({ rows: selectMask, columns: columns }); // true or false for each index value, depending on if it is found from the rows parameter
    return selected;
}

const QueryView: React.FC = () => {

    const { t } = useTranslation()

    const { id: forecastId, fromYear, toYear } = useAppSelector(selectCurrentForecast)!

    const { data: forecast } = useGetAdjustedDataForecastForForecastQuery({ forecastId: forecastId! })
    const { data: history } = useGetDetailedHistoryQuery()
    const { data: areas } = useGetAreasQuery()
    const currentMunicipality = useAppSelector(selectCurrentMunicipality)

    const [indexedForecast, setIndexedForecast] = useState<IndexedCohortByAreaByYearType[] | undefined>(undefined)

    useEffect(() => {
        if (forecast && history && !indexedForecast) {
            const oldIndexed = indexCohorts(forecast.adjusted_projections[OLD_PRODUCTION_IDX])
            const newIndexed = indexCohorts(forecast.adjusted_projections[NEW_PRODUCTION_IDX])
            const historyIndexed = indexCohorts(history)
            setIndexedForecast([oldIndexed, newIndexed, historyIndexed])
        }
    // eslint-disable-next-line
    }, [forecast, history])

    const [forecastYears, setForecastYears] = useState<number[]>([])
    const [dataframe, setDataframe] = useState<dfd.DataFrame | undefined>(undefined)

    const [currentYear, setCurrentYear] = useState<number>(fromYear)
    const [ages, setAges] = useState<{ from: number, to: number }>({ from: 0, to: 99 })
    const [selectedAreas, setSelectedAreas] = useState<string[]>([])

    const [locColumns, setLocColumns] = useState<string[]>([])

    const filterAreasPerYear = (filterYear:number) => {

        const smallAreas = selectedAreas.filter(area => area.length > 4)
        const areasPerYear = forecastYears.flatMap(year => smallAreas.map(area => `${year}_${area}`))
        return areasPerYear.filter(area => area.startsWith(`${filterYear}_`))
    }

    const filterColumns = () => setLocColumns(getLocColumns(ages))
    const [ maxAgeGroupSize, setMaxAgeGroupSize ] = useState(0)

    useEffect(() => {

        if (areas) {

            const areaIds = Object.keys(areas[SMALL_AREA_IDX])
                .concat(
                    Object.keys(areas[MAJOR_AREA_IDX])
                        .filter(area => !area.endsWith(UNKNOWN_AREA_ID))
                )

            setSelectedAreas(areaIds)
        }
        // eslint-disable-next-line
    }, [areas])

    useEffect(() => {
        if (forecast) {
            const [ oldProduction, newProduction ] = forecast.adjusted_projections
            const df = jsonToDataFrame(oldProduction, newProduction)
            setDataframe(df)
            setForecastYears(Array.from({ length: toYear - fromYear + 1 }, (_, i) => fromYear + i))
        }
    }, [forecast, fromYear, toYear])

    useEffect(() => {
        if (!dataframe) return
        const maxSizes = Array.from({ length: toYear - fromYear + 1 }, (_, i) => fromYear + i).map(year => {
            const filteredDataframe = selectRowsAndColumns(dataframe, filterAreasPerYear(year), locColumns)
            return findMaxAgeGroupSize(calculateAgeGroups(filteredDataframe))
        })
        setMaxAgeGroupSize(Math.max(...maxSizes))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataframe, fromYear, toYear, locColumns, selectedAreas])

    useEffect(() => {
        filterAreasPerYear(currentYear)
    // eslint-disable-next-line
    }, [currentYear, selectedAreas, areas, forecastYears])

    useEffect(() => {
        filterColumns()
    // eslint-disable-next-line
    }, [ages])

    const [tempYear, setTempYear] = useState<number>(fromYear)
    const [tempAges, setTempAges] = useState<{ from: number, to: number }>({ from: 0, to: 99 })

    // Area names for the download component, to be included in the CSV file

    const { code: currentMunicipalityCode, name: currentMunicipalityName } = currentMunicipality

    const allAreas = areas ? areas.flatMap(area => Object.entries(area)).reduce((prev, [ key, value ]) => ({ ...prev, [key]: value }), {} as AreaType) : {}
    const areaNames: Record<string,string> = allAreas ? Object.entries(allAreas).reduce((prev, [areaId, { name }]) => ({ ...prev, [areaId]: name }), { [currentMunicipalityCode]: currentMunicipalityName }) : {}
    
    return areas?.length && dataframe?.values.length ? (
        <Grid2 container width={'100%'} height={'80%'}>
            <Grid2 xs={3} height={'100vh'}>
                <Typography variant='h3' style={{ fontSize: '1rem', fontWeight: 700 }}>{t('queryView.areas')}</Typography>
                <AreaSelection
                    areas={getAreaHierarchy(areas![1], areas![0])}
                    selectedAreas={selectedAreas}
                    setSelectedAreas={setSelectedAreas}
                    props={{ height: '90vh' }}
                />
                {
                    // @ts-ignore
                    <Slider
                        sx={{ paddingTop: '2.5rem', alignSelf: 'flex-end' }}
                        title={t('general.ages')}
                        min={0}
                        max={99}
                        value={[tempAges.from, tempAges.to]}
                        onChange={(e, [from, to]: number[]) => setTempAges({ from, to })}
                        onChangeCommitted={(e, [from, to]: number[]) => setAges({ from, to })}
                        valueLabelDisplay='on'
                        marks={[
                            { value: 0, label: '0' },
                            { value: 99, label: '99' }
                        ]}
                    />
                }
                <DownloadComponent
                    data={dataframe}
                    areaNames={areaNames}
                    selectedAreas={selectedAreas.filter(area => area.length > 4)}
                    selectedAges={ages}
                    ageGroupingMap={{
                        '1-v. ikäryhmät': generateOneYearAgeGroups(0, 99),
                        '5-v. ikäryhmät': generateFiveYearAgeGroups(0, 99),
                        'Elämänvaiheen mukaan': [{from: 0, to: 0}, {from: 1, to: 2}, {from: 3, to: 6}, {from: 7, to: 12}, {from: 13, to: 15}, {from: 16, to: 18}, {from: 19, to: 24}, {from: 25, to: 64}, {from: 65, to: 74}, {from: 75, to: 84}, {from: 85, to: 99}],
                        'Päivähoitoikäiset': [{from: 0, to: 6}],
                        'Nuoret': [{from: 0, to: 28}],
                    }}
                />
            </Grid2>
            <Grid2 container xs={9}>
                <Grid2 xs={12} container>
                    <Grid2 xs={7}>
                        <AdjustedPopulationChangeChart
                            data={indexedForecast}
                            areas={selectedAreas.filter(area => area.length > 4)}
                            ages={ages}
                        />
                    </Grid2>
                    <Grid2 xs={5}>
                        <DataframeAgeGroupChart
                            currentYearData={selectRowsAndColumns(dataframe, filterAreasPerYear(currentYear), locColumns)}
                            firstYearData={selectRowsAndColumns(dataframe, filterAreasPerYear(fromYear), locColumns)}
                            maxSize={maxAgeGroupSize}
                        />
                        <Slider
                            title='test'
                            min={fromYear}
                            max={toYear}
                            value={tempYear}
                            onChange={(_, value) => setTempYear(Number(value))}
                            onChangeCommitted={(_, value) => setCurrentYear(Number(value))}
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
    ) : null
};

export default QueryView;