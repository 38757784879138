import { Box, FormControl, FormControlLabel, FormLabel, List, ListItem, ListItemText, Radio, RadioGroup } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useEffect, useMemo, useState } from "react"
import { GeoJSONGeometry } from "wellknown"
import PopulaMap from "../../components/Maps"
import { useGetBboxQuery, useLazyGetSpatialDataQuery } from "../apis/spatialSlice"

type LayerSwitcherProps = {
    layers: { id: number, label: string }[],
    value: number,
    onChange: (layerId: number) => void
}

const LayerSwitcher = ({ layers, value, onChange }:LayerSwitcherProps) => {

    return (
        <FormControl sx={{ right: 0, backgroundColor: '#fff', opacity: 0.7 }}>
            <FormLabel sx={{ color: '#000' }}>Valitse näytettävät alueet</FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={({ target }) => onChange(parseInt(target.value))}        
            >
                {
                    layers.map((layer, idx) =>
                        <FormControlLabel key={idx} value={layer.id} control={<Radio />} label={layer.label} />
                    )
                }
            </RadioGroup>
        </FormControl>
    )
}

const AreaList = ({ onSelectArea, data }:{ onSelectArea: (area:string) => void, data: { name: string }[]}) => {
    
    return (
        data ?
        <Box sx={{ maxHeight: '755px', overflow: 'auto' }}>
        <List
            dense
        >
            {
                Object.values(data).map((area, idx) => (
                    <ListItem key={idx} button onClick={() => onSelectArea(area.name)}>
                        <ListItemText primary={area.name} />
                    </ListItem>
                ))
            }
        </List>
        </Box> : null
    )
}

export const MapView = ({ municipalityCode, spatial }: { municipalityCode:string, spatial: {
    id: number;
    label: string;
    geojson: GeoJSONGeometry;
}[]}) => {

    const { data: bboxData } = useGetBboxQuery()

    const bbox = useMemo(() => bboxData, [bboxData])

    const [ triggerGeometryFetch, geometryResult ] = useLazyGetSpatialDataQuery()

    const [ value, setValue ] = useState(1);
    const [ chosenArea, setChosenArea ] = useState<string | null>(null)

    useEffect(() => {
        if (spatial && bbox && spatial.length > 0) {
            triggerGeometryFetch({ spatialId: 1 })
        }
    }, [ spatial, bbox, triggerGeometryFetch ])

    const onLayerChange = (spatialId: number) => {
        triggerGeometryFetch({ spatialId })
        setChosenArea(null)
        setValue(spatialId)
    }

    return (
        bbox &&
        <Grid2 container maxHeight={'500px'} paddingTop={'1rem'}>
            <Grid2 xs={2} sx={{ height: '100%' }}>
                <AreaList
                    onSelectArea={(area) => setChosenArea(area)}
                    data={geometryResult.data?.geojson.map((g:any) => ({ name: g.properties.name as string }) ).filter(area => area.name) || [] }
                />
            </Grid2>
            <Grid2 xs={10} sx={{ height: '100%' }}>
                <Box sx={{height: '100%' }}>
                    <PopulaMap
                        bbox={bbox!}
                        geometries={geometryResult.data}
                        chosenArea={chosenArea}
                    />
                    <LayerSwitcher
                        value={value}
                        layers={spatial!}
                        onChange={onLayerChange}
                    />
                </Box>
            </Grid2>
        </Grid2>
    ) || null
}