import { useMemo } from 'react'
import { ResponsiveContainer } from 'recharts'
import { useAppSelector } from '../../app/hooks'
import { AdjustmentsChart } from '../../components/charts/ComposedCharts'
import { useGetAdjustedSummaryQuery } from '../apis/apiSlice'
import { selectCurrentForecastId } from "../apis/appSlice"

const AdjustmentView = () => {

    const { id: forecastId } = useAppSelector(selectCurrentForecastId)!
    const { data } = useGetAdjustedSummaryQuery({ forecastId })

    const adjustmentData = useMemo(
        () => Object.entries(data?.unadjusted_sums || {})
            .map(([year, adjustments]) => {

                const { projections, target: areaForecast } = adjustments
                const [existingBuildings, newBuildings] = projections

                return {
                    year: Number(year),
                    existingBuildings,
                    newBuildings,
                    areaForecast,
                }
            }
            ),
        [data]
    )

    return (
        <div>
            <ResponsiveContainer aspect={1.85} width={'100%'}>
                <AdjustmentsChart adjustmentData={adjustmentData} width={1} height={1}  />
            </ResponsiveContainer>
        </div>
    )
}

export default AdjustmentView